<template>
    <div ref="chart" id="chart" style="width: 100%;height: 100%;">
         
    </div>
 </template>
<script>
import * as echarts from 'echarts';
import {mergeObjects} from '@/global/utils'
export default {
    props:{
        date:Array,
        data:Array
    },
    data(){
        return{
            options:{
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: [],
                    splitLine:{
                        show:true
                    }
                },
                
                yAxis: {
                    type: 'value',
                    splitLine:{
                        show:false
                    }
                },
                tooltip:{
                    show:true,
                    trigger:'axis'
                },
                series: [
                    {
                        // data: [820, 932, 901, 934, 1290, 1330, 1320],
                        data:[],
                        type: 'line',
                        lineStyle: {color:'#20A0FF'},
                        smooth:true,
                        symbolSize:0,
                        itemStyle:{color:'#20A0FF'},
                        areaStyle: {
                            normal: {
                                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: '#20A0FF' // 起始位置的颜色
                                }, {
                                    offset: 1,
                                    color: '#f2f2f2' // 结束位置的颜色
                                }])
                            }
                        }

                    }
                ]
            }
        }
    },
    watch:{
        data(){
            this.init()
        }
    },
    
    methods:{
        init(){
            let r = this.$props
            this.options.xAxis.data = r.date
            this.options.series[0].data = r.data
            var myChart = echarts.init(this.$refs.chart)
            
            myChart.setOption(this.options,true)
            window.addEventListener('resize', function () {
                myChart.resize();
            });
        }
    }
}
</script>



<style scoped>

</style>