<template>
   <div ref="barchart" style="width: 100%;height: 100%;"></div>
</template>

<script>
import * as echarts from 'echarts';
export default {
    props:{
        title:Array,
        data:Array
    },
    data(){
        return{
            options:{
                legend: {},
                tooltip: {},
                dataset: {
                    dimensions: [],
                    source: [
                        // { product: 'Matcha Latte', 2015: 43.3, 2016: 85.8, 2017: 93.7 },
                        // { product: 'Milk Tea', 2015: 83.1, 2016: 73.4, 2017: 55.1 },
                        // { product: 'Cheese Cocoa', 2015: 86.4, 2016: 65.2, 2017: 82.5 },
                        // { product: 'Walnut Brownie', 2015: 72.4, 2016: 53.9, 2017: 39.1 }
                    ]
                },
                xAxis: { type: 'category' },
                yAxis: {},
                // Declare several bar series, each will be mapped
                // to a column of dataset.source by default.
                series: [
                    { type: 'bar',itemStyle:{color:'#FAC858'} }, 
                    { type: 'bar',itemStyle:{color:'#93CF76'} },
                    { type: 'bar',itemStyle:{color:'#5aabff'} }
                ]
            }
        }
    },
    watch:{
        data(val){
            this.options.dataset.dimensions = this.$props.title
            this.options.dataset.source = val
            // console.log(val)
            this.init()
        }
    },
    methods:{
        init(){
            var myChart = echarts.init(this.$refs.barchart)
            
            myChart.setOption(this.options,true)
            window.addEventListener('resize', function () {
                myChart.resize();
            });
        }
    }
}
</script>

<style scoped>

</style>