<template>
    <div class="screen_main">
        <div class="mob_nav"><MobNav /></div>
        <div class="d-flex h-full">
            <el-row class="flex-1" :gutter="10" style="height: 100%;">
                <el-col :md="12" style="height: 47%;margin-bottom: 1%;">
                    <div class="box">
                        <div class="line">教师得分分析</div>
                        <OneLineChart :date="one_line_options.xAxisData" :data="one_line_options.seriesData"/>
                    </div>
                </el-col>
                <el-col :md="12" style="height: 47%;margin-bottom: 1%;">
                    <div class="box">
                        <div class="line">评课分类分析</div>
                        <OneBarChart :data="one_bar_options.data" :title="one_bar_options.title"/>
                    </div>
                </el-col>
                <el-col :md="12" style="height: 47%;margin-bottom: 1%;">
                    <div class="box">
                        <div class="line">当前课程教师得分分析</div>
                        <OneRadarChart :indicator="one_radar_options.dataset.indicator" :series="one_radar_options.series"/>
                    </div>
                </el-col>
                <el-col :md="12" style="height: 47%;margin-bottom: 1%;">
                    <div class="box">
                        <div class="line">教研资源推荐</div>
                        <div class="d-flex flex-column a-center j-center h-100" v-if="!source_lists">
                            <img src="../../assets/empty_1.png" />
                            <div style="margin-top: 0.5rem;color: #B4B4B4;font-size: 14px;">当前还没教研资源推荐11</div>
                        </div>
                        <el-table height="100%" v-else :data="source_lists" style="width: 100%;height: 100%;">
                            <el-table-column prop="id" label="序号"></el-table-column>
                            <el-table-column prop="name" label="资源名称"></el-table-column>
                        </el-table>
                    </div>
                </el-col>
            </el-row>
            <div class="d-flex a-center portrait_nav screen_nav"><Nav :params="{lesson_id:lesson_id,teacher_id:teacher_id}"/></div>
        </div>
    </div>
</template>
<script>
import oneLineChart from './comp/onelinechart.vue'
import oneBarChart from './comp/onebarChart.vue'
import OneRadarChart from './comp/oneradarChart.vue'
import {recently,lesson_chart,lesson_source} from '@/request/api/lession.js'
import MobNav from '@/components/MobileMenu.vue'
import Nav from './comp/nav.vue'
export default {
    components:{
        OneLineChart:oneLineChart,
        OneBarChart:oneBarChart,
        OneRadarChart,
        Nav,
        MobNav,
    },
    data(){
        return{
            teacher_id:'', //教师id
            lesson_id:'',//课程id
            one_line_options:{
                seriesData:[],
                xAxisData:[]
            },
            one_bar_options:{
                title:['product','机评','专家评','最终评分'],
                data:[
                    // {"product":"测评指标分组1",'机评': 6,'专家评': 5,'最终评分': 12}
                ]
            },
            one_radar_options:{
                series:[],
                dataset:{
                    indicator:[]
                }
            },
            source_lists:null ,//教研资源推荐
        }
    },
    created(){
        this.teacher_id = this.$route.query.teacher_id || ''
        this.lesson_id = this.$route.query.lesson_id || ''
        this.init()
    },
    methods:{
        init(){
            if(!this.lesson_id) return;
            recently(this.teacher_id).then(res=>{
                let date = [];
                let data = [];
                res.data && res.data.forEach(item => {
                    let finalScore = item.finalScore ? item.finalScore : 0
                    let createTime = item.createTime ? item.createTime : ''
                    date.push(createTime)
                    data.push(finalScore)
                    
                });
                this.one_line_options.xAxisData = date
                this.one_line_options.seriesData = data
            })
            this.get_lesson_chart()
            this.get_lesson_source()
        },
        get_lesson_chart(){
            lesson_chart(this.lesson_id).then(res=>{
                this.one_bar_options.data = res.arr
                this.one_radar_options.dataset.indicator = res.indicator_data
                this.one_radar_options.series.push({
                    name: '得分',
                    type: 'radar',
                    data: [
                        {
                            value: res.radar_data,
                            name: '得分'
                        }
                    ]
                
                })
                    
            })
            
        },
        get_lesson_source(){
            lesson_source(this.lesson_id).then(res=>{
                this.source_lists = res.data
            })
        }
    }
}
</script>
<style scoped lang="less">
.bg-white {
    background-color: #fff;
}
.line {
    padding-left: 10px;
    border-left: 4px solid #409EFF;
    font-size: 14px;
}
.box {
    background-color: #fff;
    padding: 15px;
    height: 100%;
    box-sizing: border-box;
}
</style>